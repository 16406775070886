import ApiService from "@/core/services/api.service";

export const POWERLINE_LIST_REQUEST = "powerline/listRequest";
export const POWERLINE_LIST_SUCCESS = "powerline/listSuccess";
export const POWERLINE_LIST_ERROR = "powerline/listError";
export const POWERLINE_SET_CURRENT = "powerline/setCurrent";
export const POWERLINE_CLEAR_CURRENT = "powerline/clearCurrent";

const state = {
  list: [],
  currentPowerline: null,
  isLoading: false,
  error: null,
  totalElements: 0
};

const actions = {
  [POWERLINE_LIST_REQUEST]: ({ commit }, params) => {
    commit(POWERLINE_LIST_REQUEST);
    return ApiService.query("/api/oscillogram/powerline/paginated", { params })
      .then(({ data }) => {
        commit(POWERLINE_LIST_SUCCESS, data);
        return data;
      })
      .catch((error) => {
        commit(POWERLINE_LIST_ERROR, error);
        throw error;
      });
  },
  [POWERLINE_SET_CURRENT]: ({ commit }, powerline) => {
    commit(POWERLINE_SET_CURRENT, powerline);
  },
  [POWERLINE_CLEAR_CURRENT]: ({ commit }) => {
    commit(POWERLINE_CLEAR_CURRENT);
  }
};

const mutations = {
  [POWERLINE_LIST_REQUEST]: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  [POWERLINE_LIST_SUCCESS]: (state, data) => {
    state.isLoading = false;
    state.list = data.content;
    state.totalElements = data.totalElements;
  },
  [POWERLINE_LIST_ERROR]: (state, error) => {
    state.isLoading = false;
    state.error = error;
  },
  [POWERLINE_SET_CURRENT]: (state, powerline) => {
    state.currentPowerline = powerline;
  },
  [POWERLINE_CLEAR_CURRENT]: (state) => {
    state.currentPowerline = null;
  }
};

export default {
  state,
  actions,
  mutations
}; 