import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import api from "./api.module";
import quickAction from "./quickAction.module";
import actions from "./actions.module";
import team from "./team.module";
import powerline from "./powerline.module";
import dashboard, { updateSettingsStore } from "./dashboard/dashboard.module";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    api,
    quickAction,
    actions,
    dashboard,
    team,
    powerline,
  },
});

store.watch(
  (state) => state.dashboard.settings,
  (value) => {
    updateSettingsStore(value);
  },
  {
    deep: true,
  }
);

export default store;
