// Russia
export const locale = {
  TRANSLATOR: {
    SELECT: "Выберите язык",
    LANGUAGE: "Language",
  },
  COMMON: {
    SEGMENTS: "Участки",
    START_DATE: "Выберите дату начала",
    END_DATE: "Выберите дату окончания",
    CALCULATE: "Сделать расчет",
    YES: "Да",
    NO: "Нет",
    HELP: "Справка",
    GO: "Перейти",
    AUTHOR: "Автор",
    REPLACE: "Заменить",
    BROWSE_FILE: "Выбрать",
    DOCUMENT_UPLOADED: "Документ загружен",
    DOWNLOAD: "Скачать",
    ACTIONS: "Действия",
    ACTIVE: "Активен",
    ADD: "Добавить",
    ALL_DEVICES: "Все устройства",
    ALTITUDE: "Высота",
    APPLY: "Принять",
    ARE_YOU_SURE: "Вы уверены?",
    ARE_YOU_SURE_WANT_TO_CANCEL:
      "Вы уверены, что хотите отменить подписку %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_CANCEL: "Вы уверены, что хотите отменить %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_CHANGE:
      "Вы уверены, что хотите изменить тариф %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE: "Вы уверены, что хотите удалить %{name}?",
    ARE_YOU_SURE_YOU_WANT_TO_RESTORE:
      "Вы уверены, что хотите восстановить %{name}?",
    BACK: "Назад",
    BEGIN_DATE: "Начальная дата",
    CANCEL: "Отмена",
    CANCELED_SUCCESSFULLY: "Успешно отменено",
    CATALOGUE: "Группа устройств",
    CHANGE: "Изменить",
    CHANGED: "Изменён",
    CHOOSE_FILE_OR_DROP: "Выберите файл или перетащите его сюда...",
    CLEAR: "Очистить",
    CLOSE: "Закрыть",
    CODE: "Код",
    COMMAND: "Команда",
    CONFIRMATION: "Подтверждение",
    CREATE: "Создать",
    CREATED: "Создан",
    DELETED_SUCCESSFULLY: "Успешно удалено",
    DESCRIPTION: "Описание",
    DEVICE: "Устройство",
    DROP_FILE_HERE: "Перетащите файл сюда...",
    EMPTY_DATA: "Нет данных, соответствующих заданным условиям",
    END_DATE: "Конечная дата",
    ERROR: "Ошибка",
    EXAMPLE: "Пример",
    FILE: "Файл",
    HOURS: "Часы",
    INFO: "Информация",
    LATITUDE: "Широта",
    LONGITUDE: "Долгота",
    MAIN: "Основное",
    MAX_VALUE: "Макс. значение",
    MIN_VALUE: "Мин. значение",
    MINUTES: "Минуты",
    NAME: "Имя",
    NEXT: "Следующий",
    NEXT_STEP: "Следующий шаг",
    NO_DATE: "Дата не выбрана",
    NO_TIME: "Время не выбрано",
    PARAMETERS: "Параметры",
    PRICE: "Цена",
    SAVE: "Сохранить",
    SAVED_SUCCESSFULLY: "Успешно сохранено",
    SEARCH: "Поиск",
    SELECTED: "Выбран",
    SELECTED_DEVICES: "Выбранные устройства",
    SHARE: "Поделиться",
    SHOW_BY: "Показать",
    START_DATE: "Начальная дата",
    SUCCESS: "Успех",
    TAB_MAIN: "Главное",
    TEAM: "Команда",
    THIS_FIELD_IS_REQUIRED: "Это поле обязательно к заполнению",
    TITLE: "Заголовок",
    TRIAL: "Trial до",
    TYPE: "Тип",
    TYPE_CATALOGUE: "Тип каталога",
    TYPE_DEVICE: "Тип устройства",
    UNKNOWN_ERROR: "Неизвестная ошибка",
    USER: "Пользователь",
    WARNING: "Предупреждение",
    PLEASE_WAIT: "Пожалуйста, подождите...",
  },
  COUNTRIES: {
    COUNTRY: "Страну",
    COUNTRIES: "Страны",
  },
  ACTIONS: {
    CREATE_NEW: "Создать",
    EDIT: "Редактировать",
    DELETE: "Удалить",
    RELOAD: "Перезагрузить",
    IMPORT_DATA: "Импорт данных",
    IMPORT: "Импорт",
    ADD: "Добавить",
    VIEW: "Просмотр",
    EXECUTE: "Выполнить",
    SHOW: "Показать",
  },
  MAP: {
    SELECT_COORDS_AND_RADIUS: "Выберите координаты и радиус",
    CHANGE: "Изменить",
    CHANGE_POINT_AND_RADIUS: "Выберите точку и радиус",
    RESET: "Сброс",
  },
  MONITORING: {
    TYPE: "Тип устройства",
    TEAM: "Команда",
    IMEI: "IMEI",
    NO_DATA: "Нет данных",
    ACTIVE: "Активен",
    LAST_DATA_RECEIVED: "Последние данные получены",
    PARAMETERS: "Параметры",
    // todo translate
    ORDER: "Заказ",
    ADD_NEW_DEVICE: "Добавить новое Устройство",
    REALLY_BUY: "Вы действительно хотите приобрести %{name}?",
    SUCCESS_CREATED_ORDER:
      "Заказ успешно создан. Пожалуйста, перейдите в раздел Команда->Заказы для оплаты.",
    NO_CATALOGUE_SELECTED: "Группа устройств не выбрана",
    MODAL: {
      APPLY: "Применить",
      SELECT:
        "Пожалуйста, выберите свой тариф, чтобы открыть доступ к полному функционалу платформы и приступить к решению ваших профессиональных задач",
      INPUT: "Если у вас есть промокод, введите его здесь",
      INPUT_PLACEHOLDER: "Промокод",
    },
  },
  FEEDBACK: {
    SEND_FEEDBACK: "Отправка отзыва",
    DESCRIPTION_PROBLEM: "Опишите здесь свою проблему или отзыв",
    SCREENSHOT: "Скриншот",
    MAKE_SCREENSHOT: "Кликните здесь, чтобы сделать скриншот",
    SUBMIT: "Отправить",
    SELECT_RECTANGLE: "Начертите прямоугольник, чтобы обрезать снимок экрана",
    FEEDBACK_SENT: "Отзыв отправлен!",
  },
  POWERLINE: {
    SEGMENTS: "Участки",
    PARAMETERS_OF_SEGMENTS_AND_VERTICES: "Параметры участков и вершин",
    FOR_EACH_PAIR_OF_VERTICES_YOU_NEED_TO_SET: "Для каждой пары вершин нужно задать:",
    SEGMENT_NUMBER_CONNECTING_THEM: "Номер участка, который их соединяет",
    DISTANCE_BETWEEN_VERTICES_IN_METERS: "Расстояние между вершинами в метрах",
    SIGNAL_SPEED_IN_METERS_PER_SECOND: "Скорость сигнала в метрах в секунду",
    ENTER_THE_TOTAL_NUMBER_OF_VERTICES: "Введите общее количество вершин",
    DISTANCE: "Расстояние",
    SPEED: "Скорость",
    EMPTY_IF_VERTICES_ARE_NOT_CONNECTED: "(пусто, если вершины не соединены)",
  },
  DEVICE: {
    COMMANDS_NOT_FOUND: "Для этого устройства не найдено ни одной команды",
    IMEI: "IMEI",
    EXECUTE_DEVICE_COMMAND: "Выполнить команду устройства",
    SELECT_COMMAND: "-- Выбрать команду --",
    OTHER_TEAM_PUBLIC_DEVICE:
      "Это общедоступное устройство другой команды - Вы можете пользоваться им только в режиме чтения. Вы можете создать себе устройство с полным доступом.",
    TYPE_DEVICE_TYPE_NAME: "Введите название типа устройства",
    PHONE_NUMBER: "Номер телефона",
    CHOOSE_COORDINATES: "Выберите координаты",
    PUBLIC: "Публичный",
    PARAMETER_MAPPING: "Соотношение параметров",
    ADDITIONAL_DEVICES: "Дополнительные устройства",
    PARAMETER_ADD_INFO:
      "Вы можете добавить параметр для своего устройства. Следующий набор параметров указывать вручную не нужно, так как он был настроен автоматически:",
    ADD_PARAMETER_MAPPING: "Добавить параметр",
    EDIT_PARAMETER_MAPPING: "Редактировать параметр",
    TRANSLATE_EXPRESSION: "Выражение",
    DEVICE_TYPE: "Тип устройства",
    IMPORT_DEVICE_DATA: "Импорт данных устройства",
    LAST_DATA_RECEIVED: "Дата последнего обмена",
    CREATE_NEW_DEVICE: "Создание нового Устройства",
    PARAMETER: "Параметр",
    EDIT_DEVICE: "Редактирование Устройства",
    EDIT_POWERLINE: "Редактирование ЛЭП",
    /*Device types*/
    LOCATION_MANUAL: "Расположение указывается вручную",
    INTEGRATION: "Интеграция",
    TRUST_LEVEL: "Уровень достоверности",
    AMEND_TIMEZONE: "Правка часового пояса",
    COMMANDS: "Команды управления",
    ADD_COMMAND: "Добавить команду управления",
    EDIT_COMMAND: "Редактировать команду управления",
    VALUE: "Значение",
    ADD_PROPERTY: "Добавить свойство",
    CODE: "Код",
    CREATE_NEW_DEVICE_TYPE: "Создание нового Типа Устройства",
    EDIT_DEVICE_TYPE: "Редактирование Типа Устройства",
    SELECT_DEVICE_TYPE: "-- Выберите тип устройства --",
    DEFAULT_VALUE: "Значение по умолчанию",
    DEVICE: "Устройство",
    DEVICES: "Устройства",
    IMPORT_COMPLETED: "Импорт завершен",
    IMPORT_FAILED: "Не удалось импортировать",
    IMPORT_FAILED: "Не удалось экспортировать",
    DEVICE_FIELDS: "Дополнительно",
    FIELD_ERROR: "Недопустимое значение дополнительных параметров",
    REMOVE_COMPOUND: "Удалить параметры",
    ADD_COMPOUND: "Добавить параметры",
    COMPOUND_ENTRY: "Параметры пролёта",
    CHOOSE_COMPOUND: "Выберите номер",
    COMPOUND_PROMPT: "Выберите номер из списка",
    COMPOUND_GROUP_PROMPT: "Выберите группу из списка",
    SPAN: "Пролёт",
    SPAN1: "Опять пролёт", // TODO: Это тестовая строка
    GRIDNUM: "Сеть",
    SENSORNUM: "Датчик",
    FORECAST: "Загружать прогноз",
    FORECAST_TOGGLE: "Отображать прогноз",
  },
  CATALOGUE: {
    NAME: "Название",
    DESCRIPTION: "Описание",
    DEVICES: "Устройства",
    /*CHOOSE_DEVICE: "Choose device",
    SELECTED_DEVICES: "Selected devices",*/
    CREATE_NEW_CATALOGUE: "Создание новой Группы устройств",
    EDIT_CATALOGUE: "Редактирование Группы устройств",
  },
  MESSAGES: {
    MEASURED: "Измерено",
    DEVICE: "Устройство",
    MESSAGE_TYPE: "Тип сообщения",
    DATA: "Данные",
    SELECT_MESSAGE_TYPE: "-- Тип сообщения --",
    UNIQUE_ID: "Уникальный ID",
  },
  PARAMETERS: {
    TYPE_TEAM_NAME: "Введите название команды",
    SELECT_UNIT: "-- Выберите единицу измерения --",
    NAME: "Имя",
    DESCRIPTION: "Описание",
    CODE: "Код",
    UNIT: "Единица измерения",
    GLOBAL: "Глобальный",
    CIRCLE: "Круговой",
    CONCENTRATION: "Концентрация",
    CALCULATED: "Вычисляемый",
    CALCULATE_EXPRESSION: "Формула вычисления",
    ATTACHMENT: "Вложение",
    CREATE_NEW_PARAMETER: "Создание нового Параметра",
    EDIT_PARAMETER: "Редактирование Параметра",
    ALERT:
      "Это глобальный параметр - Вы можете пользоваться им только в режиме чтения.\n" +
      "                Вы можете создать свой собственный параметр с полным доступом.",
    PARAMETER: "Параметр",
    PARAMETERS: "Параметры",
  },
  DATA_PACKAGES: {
    RADIUS: "Радиус",
    PARAMETERS: "Параметры",
    CREATE_NEW_DATA_PACKAGE: "Создание нового Пакета Данных",
    EDIT_DATA_PACKAGE: "Редактирование Пакета Данных",
    NO_DEVICE_FOUND: "В выбранной области устройства не найдены",
    PREVIEW_IMAGE: "Изображение для магазина данных",
  },
  ACCOUNTS: {
    SELECT_ACCOUNT_TYPE: "-- Выберите тип аккаунта --",
    SELECT_CURRENCY: "-- Выберите валюту --",
    CURRENCY: "Валюта",
    BALANCE: "Баланс",
    EXTERNAL_ID: "Внешний ID",
    TEAM_NAME: "Имя команды",
  },
  TRANSACTIONS: {
    SELECT_TRANSACTION_TYPE: "-- Выберите тип транзакции --",
    TYPE_USER_NAME: "Введите имя пользователя",
    AMOUNT: "Количество",
    BALANCE_BEFORE: "Баланс до",
    BALANCE_AFTER: "Баланс после",
    TRANSACTION_TYPE: "Тип транзакции",
  },
  PAYMENTS: {
    SELECT_PAYMENT_TYPE: "-- Выберите способ оплаты --",
    SELECT_PAYMENT_SYSTEM: "-- Выберите платежную систему --",
    SELECT_PAYMENT_STATE: "-- Выберите состояние платежа --",
    PAYMENT_SYSTEM: "Платежная система",
    PAYMENT_STATE: "Состояние платежа",
    FEE: "Комиссия",
    REGISTERED_AMOUNT: "Зарегистрированная сумма",
    TOTAL_AMOUNT: "Общая сумма",
    CANCEL_PAYMENT: "Отменить платеж",
    RESTORE_PAYMENT: "Восстановить платеж",
    ACCOUNT: "Счёт",
    TYPE_ACCOUNT: "Тип счёта",
    EXTERNAL_IDENTIFIER: "Внешний идентификатор",
    PAYMENT_UID: "UID Платежа",
    CREATE_NEW_PAYMENT: "Создать новый платеж",
    PAYMENT_SUCCESSFUL: "Оплата прошла успешно!",
    PAYMENT_ERROR: "Платеж отклонен",
    PAYMENT_THANK_YOU:
      "Благодарим вас за оплату. Автоматическая квитанция об оплате будет отправлена на вашу электронную почту.",
    PAYMENT_ERROR_MESSAGE:
      "Возникла проблема с обработкой вашего платежа. Пожалуйста, попробуйте еще раз.",
    PAYMENT_GO_TO_MY_TEAM: "Перейти к моей Команде",
  },
  PRODUCTS: {
    SELECT_PRODUCT_TYPE: "-- Выберите тип товара --",
    PRODUCT_TYPE_NAME: "Тип товара",
    PRICE: "Цена",
    REFERENCE: "Ссылка на объект",
    PRIMARY: "Основной",
    OWNERSHIP: "Тип владения",
    LIFETIME: "Продолжительность жизни (мсек)",
    CHOOSE_PRODUCT: "Выберите товар",
    CREATE_NEW_PRODUCT: "Создание нового Товара",
    EDIT_PRODUCT: "Редактирование Товара",
    DEFERMENT: "Рассрочка (мсек)",
  },
  DISCOUNTS: {
    DISCOUNT_TYPE_NAME: "Название типа скидки",
    REUSABLE: "Многоразовый",
    SELECT_DISCOUNT_TYPE: "-- Выберите тип скидки --",
    SECRET_CODE: "Промо-код",
    ONCE_FOR_USER: "Единоразовый для Пользователя",
    CREATE_NEW_DISCOUNT: "Создание новой Скидки",
    EDIT_DISCOUNT: "Редактирование Скидки",
  },
  ORDERS: {
    SELECT_ORDER_TYPE: "-- Выберите тип заказа --",
    USER_NAME: "Имя Пользователя",
    DISCOUNT_NAME: "Название скидки",
    TARGET_CURRENCY_NAME: "Валюта",
    PAYMENT_SYSTEM_NAME: "Платежная система",
    ORDER_STATE_NAME: "Состояние заказа",
    DELETE_ORDER: "Удалить заказ",
    CANCEL_ORDER: "Отменить заказ",
    ORDER_STATE: "Состояние заказа",
    DISCOUNT: "Скидка",
    PAYMENT_SYSTEM: "Платежная система",
    DISCOUNT_AMOUNT: "Сумма скидки",
    PAID: "Оплачено",
    VIEW_ORDER: "Просмотр Заказа",
  },
  PAYMENT_INFO: {
    ABOUT_PAYMENT_RULES: "О правилах оплаты",
    PAYMENT_RULES_HEADER:
      "Правила оплаты и безопасность платежей, конфиденциальность информации",
    PAYMENT_RULES_CONTENT_1:
      "Оплата банковскими картами осуществляется через АО «АЛЬФА-БАНК».",
    // eslint-disable-next-line max-len
    PAYMENT_RULES_CONTENT_2:
      "Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем Visa, MasterCard и Платежной системы МИР на принципах соблюдения конфиденциальности и безопасности совершения платежа, для чего используются самые современные методы проверки, шифрования и передачи данных по закрытым каналам связи. Ввод данных банковской карты осуществляется на защищенной платежной странице АО «АЛЬФА-БАНК».",
    // eslint-disable-next-line max-len
    PAYMENT_RULES_CONTENT_3:
      "На странице для ввода данных банковской карты потребуется ввести данные банковской карты: номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard, Код Дополнительной Идентификации для МИР). Все необходимые данные пропечатаны на самой карте. Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты.",
    PAYMENT_RULES_CONTENT_4:
      "Далее вы будете перенаправлены на страницу Вашего банка для ввода кода безопасности, который придет к Вам в СМС. Если код безопасности к Вам не пришел, то следует обратиться в банк выдавший Вам карту.",
    CASES_TO_REFUSAL: "Случаи отказа в совершении платежа:",
    REFUSAL_CASE_1:
      "банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись в Ваш Банк;",
    REFUSAL_CASE_2:
      "недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на банковской карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту;",
    REFUSAL_CASE_3: "данные банковской карты введены неверно;",
    REFUSAL_CASE_4:
      "истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать, обратившись в банк, выпустивший банковскую карту;",
    FOR_PAYMENT_QUESTIONS:
      "По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете обращаться по следующему телефону: %{phone}.",
    INFO_IS_CONFIDENTIAL:
      "Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер банковской карты) является конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.",
    RETURN_POLICY: "Правила возврата товара",
    CASH_REFUNDS_NOT_ALLOWED:
      "При оплате картами возврат наличными денежными средствами не допускается. Порядок возврата регулируется правилами международных платежных систем.",
    REFUND_REGULATED_BY:
      "Процедура возврата товара регламентируется статьей 26.1 федерального закона «О защите прав потребителей».",
    REFUND_RULE_1:
      "Потребитель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение семи дней;",
    REFUND_RULE_2:
      "Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара;",
    REFUND_RULE_3:
      "Потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его человеком;",
    REFUND_RULE_4:
      "При отказе потребителя от товара продавец должен возвратить ему денежную сумму, уплаченную потребителем по договору, за исключением расходов продавца на доставку от потребителя возвращенного товара, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования;",
    REFUND_1:
      "Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных средств», которое высылается по требованию компанией на электронный адрес и оправить его вместе с приложением копии паспорта по адресу %{email}",
    REFUND_2:
      "Возврат денежных средств будет осуществлен на банковскую карту в течение 21 (двадцати одного) рабочего дня со дня получения «Заявление о возврате денежных средств» Компанией.",
    REFUND_3:
      "Для возврата денежных средств по операциям проведенными с ошибками необходимо обратиться с письменным заявлением и приложением копии паспорта и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу %{email}",
    REFUND_4:
      "Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных средств начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях без учета праздников/выходных дней.",
    CONTACT_INFO: "Контактная информация",
    ALLOWED_CARDS: "К оплате принимаются карты VISA, MasterCard, МИР.",
    COMPANY_NAME: "Название компании",
    INN: "ИНН",
    OGRN: "ОГРН",
    KPP: "КПП",
    ADDRESS: "Фактический адрес",
    PHONE: "Телефон",
  },

  TARIFFS: {
    MAX_REQUEST_COUNT: "Макс. количество запросов",
    MAX_USER_COUNT: "Макс. количество пользователей",
    MAX_DEVICE_COUNT: "Макс. количество устройств",
    MAX_API_REQUEST_COUNT: "Макс. количество запросов API",
    MAX_MANAGED_TEAM_COUNT: "Макс. количество управляемых команд",
    COUNTRIES: "Страны",
    CREATE_NEW_TARIFF: "Создание нового Тарифа",
    EDIT_TARIFF: "Редактирование Тарифа",
    ALLOW_LOGO_CUSTOMIZATION: "Изменение логотипа разрешено",
    ALLOW_IP_FILTRATING: "Ограничение доступа по IP разрешено",
    ALLOW_REQUEST_LOGS: "Доступ к логам запросов",
  },
  CURRENCIES: {
    RUBLES: "Российские рубли",
    USD: "Доллары США",
    EURO: "Евро",
  },
  TEAMS: {
    ADD_MAIL: "Нажмите Enter для добавления",
    MAILING_LIST: "Эл. почта для уведомлений СКАТ",
    SELECT_TEAM_TYPE: "-- Выберите тип команды --",
    SELECT_COUNTRY: "-- Выберите страну --",
    TYPE_COUNTRY_NAME: "Введите название страны",
    OWNER: "Владелец",
    TARIFF: "Тариф",
    WELCOME_TO_NATLOGGER:
      "Добро пожаловать на платформу Natlogger SaaS! Пожалуйста, заполните информацию о\n" +
      "              Вашей команде ниже. Вы можете создать несколько отдельных команд (компаний) с разными тарифами\n" +
      "              и переключаться между ними.",
    CHANGE_TARIFF: "Изменить тариф",
    CREATE_TEAM_IS_MANAGED:
      "Управляемая команда (оплачивается с аккаунта текущей выбранной команды)",
    TAX_NUMBER: "ИНН",
    ADDRESS: "Полный юридический адрес компании, включая индекс",
    PHONE: "Телефон",
    EMAIL: "Email",
    WEB: "Веб-сайт",
    MEMBER: "Участник",
    MEMBERS: "Участники",
    NOTIFICATION_RULE: "Правило уведомления",
    NOTIFICATION_RULES: "Правила уведомления",
    ORDERS: "Заказы",
    COUNTRY: "Страна",
    EXISTED_USER: "Пользователь",
    OR_ENTER_EMAIL:
      "Или введите адрес электронной почты, если пользователь еще не зарегистрирован",
    PERMISSION: "Разрешение",
    SELECT_PERMISSION: "-- Выберите разрешение --",
    PERMISSION_NOT_SET: "Не указано разрешение",
    USER_NOT_SET: "Не указан Пользователь",
    NOTIFICATION_RULE_TYPE: "Тип правила уведомления",
    SELECT_NOTIFICATION_RULE_TYPE: "-- Выберите тип правила уведомления --",
    SELECT_TARIFF: "-- Выберите тариф --",
    OWNERSHIP_TYPE: "Тип владения",
    TOTAL: "Всего к оплате",
    STATE: "Состояние",
    SUBSCRIPTION_CANCELLED: "Подписка отменена",
    CREATE_NEW_TEAM: "Создание новой Команды",
    EDIT_TEAM: "Редактирование Команды",
    PLEASE_SET_COUNTRY: "Пожалуйста, укажите страну",
    PLEASE_SELECT_TEAM_TYPE: "Пожалуйста, выберите тип команды",
    INVITATION_SENT_SUCCESSFULLY: "Приглашение успешно отправлено",
    CANCEL_SUBSCRIPTION: "Отменить подписку",
    SUBSCRIPTION_CANCELLED_SUCCESSFULLY: "Подписка успешно отменена",
    ORDER_DELETED_SUCCESSFULLY: "Заказ успешно удален",
    PLEASE_SET_COUNTRY_BEFORE:
      "Пожалуйста, установите страну и сохраните команду",
    TARIFF_CHANGED_PLEASE:
      "Тариф изменен. Пожалуйста, произведите оплату в разделе 'Заказы'.",
    LOGO: {
      EDIT: "Изменить логотип",
      UNDO: "Отменить изменения",
      DELETE: "Удалить логотип",
    },
    DEF_EXP: "отсрочка до",
    ALLOWED_ADDRESSES: "Список разрешенных IP-адресов (с новой строки)",
    TEAM_FIELDS_NOTE:
      "Пожалуйста, заполните и сохраните эти поля всего лишь один раз. После этого они всегда будут автоматически подтягиваться в счета и акты. Нам это очень нужно, для налогового контроля Российской Федерации.",
    COMPANY_NAME: "Наименование компании",
    DIRECTOR_NAME: "Фамилия и инициалы директора",
    KPP: "КПП",
    OGRN: "ОГРН",
    RS: "Расчетный счет",
    BANK_NAME: "Банк",
    CORRESPONDENT_ACCOUNT: "Корреспондентский счет",
    BIK: "БИК",
    PLEASE_PROVIDE_CORRECT_DATA:
      "Пожалуйста, заполните все обязательные поля перед сохранением команды",
    VIEW_ORDER_DOCS: "Генерация платежных документов",
    UPLOAD_ORDER_DOCS: "Загрузить подписанные платежные документы",
    PROFORMA_INVOICE: "Счет на оплату",
    ACCEPTANCE_ACT: "Акт приема-передачи",
    INVOICE: "Счёт-фактура",
    PLEASE_SELECT_CURRENCY: "Пожалуйста, выберите валюту",
    SELECT_CURRENCY: "Выбор валюты",
    BACK_TO_DOCUMENT_SELECTION: "Вернуться к выбору документов",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_ORDER:
      "Вы действительно хотите удалить заказ?",
    ARE_YOU_SURE_YOU_WANT_TO_CANCEL_SUB:
      "Вы действительно хотите отменить подписку?",
    SELECT_TEAM_COUNTRY_FIRST:
      "Пожалуйста, сначала укажите вашу страну в разделе Основное.",
  },
  USERS: {
    PATRONYMIC: "Отчество",
    AUTHORIZATION_SCHEDULE: "График авторизации",
    ACTIVE_UNTIL: "Действителен до",
    APPROVED: "Утверждено",
    BLOCKED: "Заблокирован",
    PASSWORD: "Пароль",
    EMPTY_MEANS_NO_CHANGES: "Пусто означает без изменений",
    CONFIRM_PASSWORD: "Подтвердите пароль",
    IDENTIFIERS: "Идентификаторы",
    CODE: "Код",
    VERIFIED: "Проверен",
    CREATE_NEW_USER: "Создание нового Пользователя",
    EDIT_USER: "Редактирование Пользователя",
    PASSWORD_MISMATCH: "Пароли не совпадают",
    USER_SUCCESSFULLY_REGISTERED: "Пользователь успешно зарегистрирован",
    IDENTIFIER_VALUE_IS_NOT_SET: "Значение идентификатора не установлено",
    IDENTIFIER_TYPE_IS_NOT_SET: "Тип идентификатора не установлен",
  },
  ROLES: {
    GRANTS: "Права",
    CREATE_NEW_ROLE: "Создание новой Роли",
    EDIT_ROLE: "Редактирование Роли",
  },
  TOKENS: {
    TYPE_IDENTIFIER_VALUE: "Введите значение идентификатора",
    IDENTIFIER: "Идентификатор",
    LAST_REQUEST: "Последний запрос",
    CREATE_NEW_TOKEN: "Создание нового Токена",
    EDIT_TOKEN: "Редактирование Токена",
    VALUE: "Значение",
  },
  PROPERTIES: {
    VISIBLE_FOR_ADMIN: "Видимо для администратора",
    CREATE_NEW_PROPERTY: "Создание нового Свойства",
    EDIT_PROPERTY: "Редактирование Свойства",
  },
  ML_MODELS: {
    CREATE_NEW_MODEL: "Создание новой модели",
    EDIT_MODEL: "Редактирование модели",
  },
  TEMPLATES: {
    SELECT_TEMPLATE_TYPE: "-- Выберите тип шаблона --",
    LOCALE: "Язык",
    BODY: "Тело",
    CREATE_NEW_TEMPLATE: "Создание нового Шабло��а",
    EDIT_TEMPLATE: "Редактирование Шаблона",
  },
  NOTIFICATIONS: {
    TITLE: "Заголовок",
    SOURCE: "Источник",
    DESTINATION: "Назначение",
    NOTIFICATION_STATE_NAME: "Состояние уведомления",
    SELECT_NOTIFICATION_TYPE: "-- Выберите тип уведомления --",
  },
  PASSPHRASE_TYPES: {
    VALIDATION_REGEX: "Регулярное выражение проверки",
    ENCRYPTION: "Шифрование",
    GENERATOR: "Генератор",
    CREATE_NEW_PASSPHRASE_TYPE: "Создание нового Типа Пароля",
    EDIT_PASSPHRASE_TYPE: "Редактирование Типа Пароля",
  },
  TASKS: {
    SERVICE_NAME: "Наименование сервиса",
    LAST_EXECUTION_DATE: "Дата последнего исполнения",
    EXECUTE_LOG: "Просмотреть журнал",
    CRON_EXPRESSION: "Cron-выражение",
    CREATE_NEW_TASK: "Создать новую задачу",
    EDIT_TASK: "Редактировать задачу",
    TASK: "Задача",
    STATE: "Состояние",
    METHOD: "Метод",
    TASK_EXECUTION_LOG: "Лог выполнения задачи",
  },
  FEEDBACKS: {
    QUESTION: "Вопрос",
    TEXT: "Текст",
    FEEDBACK_TYPE: "Тип обратной связи",
    VIEW_FEEDBACK: "Просмотр",
  },
  NEWS: {
    SORT_ORDER: "Порядок сортировки",
    ACTIVE_FROM: "Активна с",
    ACTIVE_TO: "Активна до",
    NEWS: "Новости",
    DATE: "Дата",
    BLOG: "Блог",
    CREATE_NEW_BLOG: "Создать новую запись в блоге",
  },
  PERSONAL_NOTIFICATIONS: {
    IS_READ: "Прочитано",
  },
  REPORTS: {
    PART_TYPE: "Часть отчета", //todo
    SORT_ORDER: "Порядок сортировки",
    GENERATE_REPORT: "Сгенерировать отчет",
    GROUP: "Группа",
    VIEW: "Отображение",
    PLEASE_SPECIFY_PARAMETERS_FOR: "Укажите параметры для {name}",
    REPORT: "Отчет",
    DATE_IS_NOT_SPECIFIED: "Дата не указана",
    THIS_A_GLOBAL_REPORT:
      "Это глобальный отчет - вы можете взаимодействовать с ним только в режиме чтения. Вы можете создать свой собственный отчет с полным доступом.",
    PARTS: "Части отчета",
    REPORT_PART_TYPE: "Тип части отчета",
    ANNOTATION: "Аннотация",
    SELECT_GROUP_TYPE: "-- Выберите тип группировки данных --",
    VIEW_TYPE: "Тип отображения",
    SELECT_VIEW_TYPE: "-- Выберите тип отображения --",
    CREATE_NEW_REPORT: "Создать новый отчет",
    EDIT_REPORT: "Редактировать отчет",
    GENERATE: "Сгенерировать",
    NEW_REPORT_PART: "Новая часть отчета",
  },
  CHECKOUT: {
    PLEASE_ENTER_YOUR_EMAIL:
      "Пожалуйста, укажите ваш e-mail на странице Профиля",
    ORDER_HAS_INVALID_STATE: "У заказа недействительный статус",
    CREATE_CHECKOUT: "Создать расчет",
    DONE: "Готово",
    INFO: "Информация",
    DISCOUNT: "Скидка",
    SUMMARY: "Суммарно",
    SUCCESSFULLY_PURCHASED:
      "Заказ был успешно оплачен. Подождите, пожалуйста...",
    CREATION_DATE: "Дата создания",
    OWNERSHIP_TYPE: "Тип владения",
    PRODUCTS: "Товары",
    PRICE: "Цена",
    CURRENCY: "Валюта",
    PROMO_DESCRIPTION:
      "Если у Вас есть промо-код - введите его в поле ниже. В ином случае - оставьте поле пустым.",
    PROMOCODE: "Промо-код",
    TOTAL_AMOUNT: "Итого",
    ALERT:
      "Если Вы хотите воспользоваться тестовым периодом - отложите оплату до его окончания.",
  },
  QUICK_USER: {
    HI: "Привет",
    USER_PROFILE: "Профиль пользователя",
    SIGN_OUT: "Выйти",
    CURRENT_TEAM: "Текущая команда",
    CLICK_TO_SWITCH: "Нажмите, чтобы переключиться",
    ADD_TEAM: "Добавить команду",
  },
  FOOTER: {
    FEEDBACK: "Обратная связь",
    PRICING: "Цены",
    BLOG: "Блог",
    CONTACT: "Контакты",
  },

  MENU: {
    ECO: "Экомониторинг",
    METEO: "Метеомониторинг",
    LINE_MONITORING: "Мониторинг сетей",
    SKAT_VOMP: "СКАТ-ВОМП",
    SKAT_GIO: "СКАТ-ГИО",
    POWERLINE: "ЛЭП",
    SCAT_DEVICES: "Устройства СКАТ",
    ML_MODELS: "Модели",
    DASHBOARD: "Рабочий стол",
    MONITORING: "Мониторинг",
    // DATA_SHOP: "Магазин",
    DEVICES: "Устройства",
    CATALOGUES: "Группы устройств",
    MESSAGES: "Сообщения",
    PARAMETERS: "Параметры",
    REPORTS: "Отчеты",
    MANAGEMENT: "Управление",
    TEAMS: "Команды",
    DEVICE_TYPES: "Типы устройств",
    DATA_PACKAGES: "Пакеты данных",
    CONTENT: "Контент",
    BLOGRU: "Блог | Русский",
    BLOGEN: "Блог | Английский",
    BLOGES: "Блог | Испанский",
    FINANCE: "Финансы",
    ACCOUNTS: "Лицевые счета",
    TRANSACTIONS: "Транзакции",
    PAYMENTS: "Платежи",
    PRODUCTS: "Товары",
    DISCOUNTS: "Скидки",
    ORDERS: "Заказы",
    TARIFFS: "Тарифы",
    ADMINISTRATION: "Администрирование",
    USERS: "Пользователи",
    ROLES: "Роли",
    TOKENS: "Токены",
    PROPERTIES: "Свойства",
    TEMPLATES: "Шаблоны",
    NOTIFICATIONS: "Уведомления",
    PASSPHRASE_TYPES: "Типы паролей",
    TASKS: "Задачи",
    FEEDBACKS: "Обратная связь",
    DOCUMENTATION: "Документация",
    API: "API",
    DOCUMENTATION_IMPORT: "Работа с данными",
    LOGS: "Логи",
    PROFILE: "Профиль",
    PERSONAL_NOTIFICATIONS: "Персональные уведомления",
    UNITS: "Единицы измерений",
  },
  DASHBOARD: {
    NONE_GROUP_TYPE_WARNING:
      "При выборе группировки типа NONE или SECOND данных может быть слишком много, что может привести к длительной загрузке или падению браузера. Пожалуйста, убедитесь, что вы используете короткий промежуток времени (день или несколько часов).",
    ADD_NEW_WIDGET: "Добавить новый виджет",
    EDIT_WIDGET: "Редактирование виджета",
    WIDGET_OPTIONS: "Виджет",
    SELECT_WIDGETS: "Выделить виджеты",
    ADD_NEW_DEVICE: "Добавить новое устройство",
    CANCEL_SELECTION: "Отменить выделение",
    DELETE_SELECTED: "Удалить выбранные виджеты",
    CLEAR: "Очистить рабочий стол",
    CHOOSE_DATA_DEVICE: "Выберите устройство",
    SELECT_TYPE: "Выбрать тип",
    SELECT_ON_MAP: "Указать на карте",
    DATA_PACKAGE: "Выберите пакет данных",
    REALTIME: "Автообновление",
    OR: "или",
    DATE_RANGE: "Диапазон дат",
    WIDGET_NAME: "Название виджета (необязательно)",
    CHART_TYPES: {
      LINE: "Линейчатая диаграмма",
      SCATTER: "Точечная диаграмма",
      BUBBLE: "Пузырьковая диаграмма",
      TABLE: "Таблица",
      PIE: "Круговая диаграмма",
      RADAR: "Лепестковая диаграмма",
      MAP: "Forecast of the movement of gas masses",
      GRID: "Сеть",
    },
    CREATE_WIDGET: "Создать виджет",
    SAVE_WIDGET: "Сохранить изменения",
    WIDGET: {
      LINK_COPIED_TO_CLIPBOARD: "Ссылка скопирована в буфер обмена",
      LEAVE_FULLSCREEN: "Выход из полноэкранного режима",
      FULLSCREEN: "Включить полноэкранный режим",
      REMOVE_WIDGET: "Удалить виджет",
      EXPORT_CSV: "Экспортировать CSV",
      EXPORT_PNG: "Экспортировать PNG",
    },
  },

  AUTH: {
    FACE: {
      WELCOME_TO_NATLOGGER: "Добро пожаловать в Natlogger!",
      THE_ULTIMATE_SAAS: "Лучшая SaaS платформа для исследования природы.",
      TWENTY_TWENTY: "© 2024 Natlogger",
      PRICING: "Цены",
      BLOG: "Блог",
      CONTACTS: "Контакты",
    },
    GENERAL: {
      OR: "Или",
      SUBMIT_BUTTON: "Отправить",
      NO_ACCOUNT: "У вас нет аккаунта?",
      SIGNUP_BUTTON: "Зарегистрироваться",
      FORGOT_BUTTON: "Забыли пароль",
      BACK_BUTTON: "Назад",
      PRIVACY: "Политика конфиденциальности",
      LEGAL: "Условия использования",
      CONTACT: "Контакты",
    },
    LOGIN: {
      TITLE: "Войти в аккаунт",
      BUTTON: "Войти",
      OR_SIGN_IN_WITH: "Или войти с помощью:",
      REPAIR_PASSWORD: "Восстановить пароль",
      PASSWORD_IS_REQUIRED: "Требуется пароль.",
      PASSWORD: "Пароль",
      EMAIL_IS_REQUIRED: "Введите корректную электронную почту.",
      EMAIL_ADDRESS: "Электронная почта",
      ENTER_YOUR_USERNAME_AND_PASSWORD: "Введите электронную почту и пароль",
      SIGN_IN: "Войти",
      DONT_HAVE_AN_ACCOUNT_YET: "У вас все еще нет аккаунта?",
      SIGN_UP: "Зарегистрироваться!",
    },
    FORGOT: {
      TITLE: "Забыли пароль?",
      DESC: "Для восстановления пароля введите ваш почтовый ящик",
      SUCCESS: "Успешное выполнение",
    },
    REGISTER: {
      TITLE: "Зарегистрироваться",
      CODE_IS_REQUIRED: "Требуется код.",
      DESC: "Введите свои данные, чтобы создать учетную запись",
      SUCCESS: "Ваша учетная запись была успешно зарегистрирована.",
      ALREADY_HAVE_AN_ACCOUNT: "Уже есть аккаунт?",
      VERIFICATION_CODE:
        "Код подтверждения отправлен на указанную электронную почту",
      VERIFICATION_CODE_MUST_HAVE:
        "Код подтверждения должен состоять из 6 символов.",
      NAME_IS_REQUIRED: "Требуется имя.",
      SURNAME_IS_REQUIRED: "Требуется фамилия.",
      PASSWORD_IS_REQUIRED: "Требуется пароль.",
      NAME_MUST_HAVE: "Имя должно содержать не более 35 букв.",
      SURNAME_MUST_HAVE: "Фамилия должна содержать не более 35 букв.",
      EMAIL_IS_REQUIRED: "Требуется электронная почта",
      EMAIL_MUST_HAVE:
        "Электронная почта должна содержать не более 254 символов.",
      PASSWORD_MUST_HAVE_LEAST: "Пароль должен состоять минимум из 3 символов.",
      PASSWORD_MUST_HAVE_MOST: "Пароль должен содержать не более 50 символов.",
      PASSWORD: "Пароль",
      PASSWORD_CONFIRMATION_IS_REQUIRED: "Требуется подтвердить пароль",
      PASSWORD_MUST_BE_IDENTICAL: "Пароли должны совпадать.",
      VALIDATION_SUCCESSFULLY: "Проверка успешно завершена, подождите ...",
      CODE: "Код",
      VERIFICATION_CODE_PLACEHOLDER: "Код подтверждения",
    },
    REPAIR: {
      ALREADY_REMEMBER_YOUR_ACCOUNT: "Вспомнили свои учетные данные?",
      SIGN_IN: "Войти!",
      PASSWORD_REPAIR: "Восстановление пароля",
      NEXT: "Далее",
      ENTER_YOUR_KNOWN_DETAILS:
        "Введите требуемые данные, чтобы восстановить доступ к своему аккаунту",
      CODE_MUST_HAVE: "Код должен содержать 6 символов.",
      CHANGE_PASSWORD: "Сменить пароль",
      PASSWORD_SUCCESSFULLY_CHANGED:
        "Пароль успешно изменен. Пожалуйста войдите в систему.",
    },
    INPUT: {
      EMAIL: "Почтовый ящик",
      FULLNAME: "Полное имя",
      PASSWORD: "Пароль",
      CONFIRM_PASSWORD: "Подтверждение пароля",
      USERNAME: "Имя пользователя",
    },
    VALIDATION: {
      NAME_REQUIRED: "Требуется имя.",
      NAME_MAX_LENGTH: "Имя должно содержать не более {length} символов.",
      SURNAME_REQUIRED: "Требуется фамилия.",
      SURNAME_MAX_LENGTH:
        "Фамилия должна содержать не более {length} символов.",
      EMAIL_REQUIRED: "Требуется электронная почта.",
      EMAIL_FORMAT: "Корректный формат - user@example.com",
      EMAIL_MAX_LENGTH:
        "Электронная почта должна содержать не более 254 символов.",
      PASSWORD_MIN_LENGTH:
        "Пароль должен состоять минимум из {length} символов.",
      PASSWORD_MAX_LENGTH:
        "Пароль должен содержать не более {length} символов.",
      PASSWORD_IDENTICAL: "Пароли должны совпадать.",
      INVALID: "{{name}} не корректно",
      REQUIRED: "Требуется {{name}}",
      MIN_LENGTH: "{{name}} минимальная длинна {{min}} символов",
      AGREEMENT_REQUIRED: "Необходимо принятие Условий использования",
      NOT_FOUND: "Поле {{name}} не найдено",
      INVALID_LOGIN: "Неверный логин",
      REQUIRED_FIELD: "Обязательное поле",
      MIN_LENGTH_FIELD: "Минимальная длина:",
      MAX_LENGTH_FIELD: "Максимальная длина:",
      INVALID_FIELD: "Значение поле введено неверно",
      PLEASE_PROVIDE_CORRECT_DATA: "Пожалуйста, укажите правильные данные.",
    },
  },
  BLOG: {
    TITLE: "Заголовок",
    TEXT: "Текст",
    IMAGE_URL: "Ссылка на изображение",
    WASTE: "Минут на чтение",
  },
  API: {
    H1: "Natlogger API",
    H2: "Общее описание",
    ABOUT:
      "В качестве архитектурного стиля взаимодействия с Natlogger API используется REST подход, который позволит Вам удобно оперировать данными.",
    H3: "Что такое REST?",
    REST:
      "REST (restful) - это общие принципы организации взаимодействия приложения/сайта с сервером посредством протокола HTTP. Особенность REST в том, " +
      "что сервер не запоминает состояние пользователя между запросами - в каждом запросе передаётся информация, идентифицирующая пользователя (например, token) и все параметры, необходимые для выполнения операции.",
    READ_MORE: "Подробнее о REST архитектуре вы можете прочитать",
    HERE: "здесь",
    DATA: "Данные для взаимодействия с Natlogger API",
    HOST: "Хост для доступа к API",
    TOKEN: "Token",
    TOKEN_NOT_AVAILABLE: "Недоступно для Вашего тарифа",
    TOKEN_DES: "поместите Token в заголовок (Header) запроса.",
    TEAM: "ID Вашей команды",
    DEFINES: "Дополнительные данные для запросов",
    GROUP_TYPE: "Группировка данных",
    REAL_TIME_RANGE: "Временной диапазон (в часах)",
    SHOW: "Показать",
    GROUP: {
      NONE: "Без группировки",
      AUTO: "Автоподбор в зависимости от временного промежутка",
      SECOND: "Секунда",
      MINUTE: "Минута",
      HOUR: "Час",
      DAY: "День",
      WEEK: "Неделя",
      MONTH: "Месяц",
      YEAR: "Год",
    },
    METHODS: "Методы для взаимодействия с Natlogger API",
    PARAMETERS: "Получение списка параметров",
    DEVICES: "Получение списка устройств",
    MEASURE: "Получение данных",
    CIRCLE: "Получение данных с группировкой по значениям",
    CSV: "Получение сырых данных в виде CSV",
    MAIN: "Основное",
    HTTP: "HTTP метод:",
    JSON: "Запрос возвращает ответ в формате json",
    CSV_FILE: "Запрос возвращает ответ в виде csv файла",
    REQUEST_PARAMS: "Параметры запроса",
    REQUEST: "Запрос",
    RESPONSE: "Ответ",
    EXAMPLE: "Пример использования",
    REQUIRED: "Обязательные",
    OPTIONAL: "Опциональные",
    UNIT_ID: "Единицы измерения",
    CALCULATE: "Рассчитывать общее количество записей в базе данных",
    ENRICH: "Обогащение справочными данными",
    COUNT: "Количество получаемых элементов выборки",
    START: "Стартовый элемент выборки",
    SORT_FIELD: "Поле для сортировки",
    SORT_DIR: "Направление для сортировки",
    NAME: "Название параметра",
    DEVICE_TYPE_IDS: "Параметры для выборки в виде набора их ID",
    PARAM_IDS: "Параметры для выборки в виде набора их ID",
    DEVICE_IDS: "Устройства для выборки в виде набора их ID",
    DATA_PACKAGE_ID: "ID пакета данных для выборки",
    PUBLIC: "Общедоступный",
    LATITUDE: "Широта точки",
    LONGITUDE: "Долгота точки",
    RADIUS: "Радиус поиска",
    START_DATE: "Начальная дата для выборки",
    END_DATE: "Конечная дата для выборки",
    GROUP_TYPES: "Группировка данных",
    ROUND: "Округление",
  },
  DOCUMENTATION_IMPORT: {
    WORK: "Работа с данными",
    IMPORT: "Импорт данных",
    IMPORT_LI1: "Подготовить файл",
    IMPORT_EXAMPLE1: "Пример файла: ",
    IMPORT_EXAMPLE2: "Документация по протоколу: ",
    IMPORT_LI2: "Создать новое (виртуальное) устройство",
    IMPORT_LI3: "Загрузить подготовленный файл через  ",
    SOKOL: "Загрузка данных из СоколМетео",
    SOKOL_LI11: "Перейдите на страницу редактирования целевого устройства в",
    SOKOL_LI12:
      "(это страница, на которой Вы можете задать или изменить IMEI и пароль устройства).",
    SOKOL_LI2:
      "В адресной строке браузера после слэша (/) Вы увидите 36-значный ID устройства.",
    SOKOL_LI3: "Скопируйте этот ID вместе с черточками.",

    SOKOL_LI4: "Для автоматической миграции данных:",
    SOKOL_LI41:
      "На платформе Natlogger создайте новое устройство с типом “Device from Sokolmeteo.com”.",
    SOKOL_LI42: "В поле IMEI введите ID скопированный на шаге 3.",

    SOKOL_LI5: "Для ручной миграции данных:",
    SOKOL_LI51: "Выгрузите данные интересующей Вас станции из ",
    SOKOL_LI52:
      "На платформе Natlogger создайте новое устройство с типом “Device from Sokolmeteo.com”.",
    SOKOL_LI53: "В поле IMEI введите ID скопированный на шаге 3.",
    SOKOL_LI54: "Загрузите данные выгруженные на шаге 5.1. следующим путём: ",
    SOKOL_DEVICES_LINK: "Устройства -> Действия -> Импорт данных.",
  },
  PROFILE: {
    ACCOUNT: "Мой аккаунт",
    CHANGE: "Изменить фото",
    UNDO: "Отменить изменения",
    DELETE: "Удалить фото",
    ABOUT: "Основное",
    NAME: "Имя",
    NAME_ENTER: "Введите Ваше имя",
    NAME_REQUIRED: "Требуется имя.",
    NAME_MAX_LENGTH: "Имя должно содержать не более 35 символов.",
    SURNAME: "Фамилия",
    SURNAME_ENTER: "Введите Вашу фамилию",
    SURNAME_REQUIRED: "Требуется фамилия.",
    SURNAME_MAX_LENGTH: "Фамилия должна содержать не более 35 символов.",
    DEFAULT_CHANEL: "Канал для уведомлений по умолчанию", // todo: не придумал как перевести
    EMAIL: "Электронная почта",
    EMAIL_ENTER: "Введите электронную почту",
    EMAIL_REQUIRED: "Требуется электронная почта.",
    EMAIL_FORMAT: "Корректный формат - user@example.com",
    EMAIL_MAX_LENGTH:
      "Электронная почта должна содержать не более 254 символов.",
    PHONE: "Телефон",
    PHONE_ENTER: "Введите Ваш телефон",
    TELEGRAM: "Telegram ID",
    TELEGRAM_ENTER: "Введите Ваш telegram ID",
    SECURITY: "Безопасность",
    PASSWORD: "Пароль",
    PASSWORD_ENTER: "Введите ваш пароль",
    PASSWORD_CONFIRMATION: "Подтверждение пароля",
    PASSWORD_CONFIRMATION_ENTER: "Подтвердите ваш пароль",
    PASSWORD_IDENTICAL: "Пароли должны совпадать.",
    SAVE: "Сохранить изменения",
  },
  LOGS: {
    CREATED: "Дата запроса",
    USER_ID: "ID Пользователя",
    METHOD: "Метод",
  },
  MULTI_SG: {
    CHOOSE: "Выберите",
    SELECTED: "Выбранные",
  },
  WIDGET: {
    SELECT_ALL_PARAMETERS: "Выбрать все параметры",
    PARAMETERS: "Параметры",
  },
  UNIT: {
    NAME: "Название",
    NAME_EN: "Название (Английский)",
    NAME_RU: "Название (Русский)",
    NAME_ES: "Название (Испанский)",
    CODE: "Обозначение",
    CODE_NOT_SET: "Не указано обозначение",
  },
  NEW_DASHBOARD: {
    CHART_GA: "График показаний ГА",
    CALCULATIONS: "Вычисления",
    AIR_TEMPERATURE: "Температура воздуха",
    SOIL_TEMPERATURE: "Температура почвы",
    SAT_AMOUNT: "Сумма САТ",
    SET_AMOUNT: "Сумма СЭТ",
    MY_DEVICES: "Мои устройства",
    PUBLIC_DEVICES: "Публичные устройства",
    POWERLINE: "ЛЭП",
    SKAT_DEVICES: "Устройства СКАТ",
    EDIT_MODE: "Режим редактирования",
    INDICATION_GRAPH: "График показаний",
    NO_DATA_FOR_PERIOD: "Нет данных для отображения за выбранный период",
    BMVD_DVP_DVL: "БМВД+ДВП и/или ДВЛ",
    SOKOL_TDV: "Сокол ТДВ",
    SOKOL_A: "Сокол А",
    SOKOL_DS: "Сокол ДС",
    SOKOL_DR800: "Сокол ДР-800",
    SOKOL_PIR: "Сокол ПИР",
    GRAPH_READINGS: "График показаний",
    TABLE_READINGS: "Таблица показаний",
    TABLE_SAT_SET: "Таблица САТ/СЭТ",
    GRAPH_SAT_SET: "График САТ/СЭТ",
    WIND_ROSE: "Роза ветров",
    GAS_LINEAR: "График показаний GA",
    MPV: "ПДК",
    DAYS: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
    MONTHS: [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь",
    ],
    ALL_READINGS: "Все показания",
    ASO: "АСО",
    TEMP_OSCILLOGRAMS: "Временные осциллограммы изменения",
    NEW_DEVICE: "Новое устройство",
    NEW_POWERLINE: "Добавить ЛЭП",
    NEW_TEMPLATE: "Добавить шаблон",
    SELECT_ALL_PARAMETERS: "Выбрать все параметры",
    MAIN_DEVICE_SELECTION: "Выбор основного устройства",
    INPUT_DATA: "Ввод данных",
    ADDITIONAL_DEVICE_SELECTION: "Выбор дополнительного устройства",
    NEXT: "Далее",
    BACK: "Назад",
    NAME_REQUIRED: "Название устройства обязательно.",
    PHONE_REQUIRED: "Номер телефона обязателен.",
    INVALID_PHONE:
      "Номер телефона должен содержать только цифры и может включать пробелы, дефисы, скобки и начинаться с '+'.",
    IMEI_REQUIRED: "IMEI обязателен.",
    PASSWORD_REQUIRED: "Пароль обязателен.",
    ACTIVE: "Активен",
    DEVICE_NAME: "Название устройства",
    DEVICE_PHONE: "Номер телефона устройства",
    IMEI: "IMEI",
    PASSWORD: "Пароль",
    ADD_TEMPLATE: "Добавить шаблон",
    CHART_SELECTION: "Выбор графика",
    DATA_INPUT: "Ввод данных",
    PARAMETER_SELECTION: "Выбор параметров",
    CHART_LINEAR: "Линейный график",
    CHART_TABLE: "Таблица",
    NAME_REQUIRED: "Имя обязательно.",
    INTERVAL_REQUIRED: "Интервал обязателен.",
    GROUP_TYPE_REQUIRED: "Тип группировки обязателен.",
    SELECT_ALL_PARAMETERS: "Выбрать все параметры",
    ADD_TEMPLATE: "Добавить шаблон",
    CHART_SELECTION: "Выбор графика",
    DATA_INPUT: "Ввод данных",
    PARAMETER_SELECTION: "Выбор параметров",
    CHART_LINEAR: "Линейный график",
    CHART_TABLE: "Таблица",
    NAME: "Имя",
    INTERVAL: "Интервал",
    GROUP_TYPE: "Тип группировки",
    AUTO_UPDATE: "Автообновление",
    NAME_REQUIRED: "Имя обязательно.",
    INTERVAL_REQUIRED: "Интервал обязателен.",
    GROUP_TYPE_REQUIRED: "Тип группировки обязателен.",
    SELECT_INTERVAL: "Выберите интервал",
    SELECT_GROUP_TYPE: "Выберите тип группировки",
    SELECT_ALL_PARAMETERS: "Выбрать все параметры",
    DAY: "День",
    WEEK: "Неделя",
    MONTH: "Месяц",
    YEAR: "Год",
    NO_GROUPING: "Без группировки",
    AUTO: "Авто",
    BY_MINUTES: "По минутам",
    BY_HOURS: "По часам",
    BY_DAYS: "По дням",
    BY_WEEKS: "По неделям",
    PARAMETERS_REQUIRED: "Вы должны выбрать хотя бы один параметр.",
    NEW_POWERLINE: "Добавить ЛЭП",
    CANCEL: "Отмена",
    NAME: "Название",
    NAME_PLACEHOLDER: "Введите название ЛЭПа",
    NAME_REQUIRED: "Название ЛЭП обязательно.",
    EPSILON: "Epsilon",
    EPSILON_PLACEHOLDER: "Введите значение Epsilon",
    EPSILON_REQUIRED: "Значение Epsilon должно быть меньше 10000 и больше 0.",
    SENSOR_NUMBER: "Номер датчика",
    SENSOR_NAME: "Название устройства",
    SENSOR_VERSION: "Версия",
    COMMUNICATION_PERIOD: "Период связи, мин",
    POWER_LINE: "ЛЭП",
    VERTEX_NUMBER: "Номер вершины",
    THRESHOLD: "Превышение",
    USTAVKA: "Уставка, dU/dt, о.е.",
    GAIN_DA1_A: "К усил chA DA1_Ch0",
    GAIN_DA1_B: "К усил chB DA1_Ch1",
    GAIN_DA2_A: "К усил chA DA2_Ch2",
    GAIN_DA2_B: "К усил chB DA2",
    MCF_THRESHOLD: "Порог MCF",
    ART_INT_THRESHOLD: "Порог Art Int",
    LOGICAL_ZEROS: "Количество лог. нулей",
    TIME_STEP: "dt, точка",
    INPUT_CIRCUIT_TYPE: "Тип входных цепей",
    NAME_REQUIRED: "Название обязательно.",
    SENSOR_NUMBER_REQUIRED: "Номер датчика обязателен.",
    VERSION_REQUIRED: "Версия обязательна и должна быть не менее 400.",
    COMMUNICATION_PERIOD_REQUIRED:
      "Период связи должен быть в диапазоне от 15 до 1020.",
    POWER_LINE_REQUIRED: "ЛЭП обязательна.",
    VERTEX_NUMBER_REQUIRED:
      "Номер вершины обязателен и должен быть не более 9999.",
    THRESHOLD_REQUIRED: "Превышение обязательное поле.",
    USTAVKA_REQUIRED: "Уставка должна быть положительной и не более 255.",
    GAIN_REQUIRED: "Коэффициент усиления обязателен.",
    MCF_THRESHOLD_REQUIRED: "Порог MCF обязателен.",
    ART_INT_THRESHOLD_REQUIRED: "Порог Art Int обязателен.",
    LOGICAL_ZEROS_REQUIRED: "Количество логических нулей обязательно.",
    TIME_STEP_REQUIRED: "dt, точка обязательна.",
    INPUT_CIRCUIT_REQUIRED: "Тип входных цепей обязателен.",
    VOLTAGE: "Напряжение",
    CURRENT: "Ток",
    STEP_1: "1. Выбор типа устройства",
    STEP_2: "2. Выбор из списка",
    STEP_3: "3. Шаблон виджета",
    DELETE: "Удалить",
    DELETION_CONFIRMATION: "Подтверждение удаления",
    DELETION_QUESTION: "Вы уверены, что хотите удалить этот виджет?",
    NO_WIDGETS_AVAILABLE: "Нет доступных виджетов",
  },
  DATA_SHOP: {
    VOTES: "{votes} голосов",
    NO_VOTES: "Нет голосов",
    RATING: "Рейтинг: {rating}/{votes}",
    DATA_COLLECTION_POINTS_COUNT: "Количество точек сбора данных",
    DATA_COLLECTION_PERIOD: "Период сбора данных",
    OTHER_DATA_PACKAGES: "Другие пакеты данных",
    DATA_COLLECTION_POINTS_ON_MAP: "Точки сбора данных на карте",
    SHOW_ON_MAP: "Показать на карте",
    DATA_PACKAGES: "Данные",
    SHOW: "Смотреть",
    VOTE: "Голосовать",
    VOTE_MODAL_HEADER: "Оцените",
    VOTING_SUCCESS: "Ваш голос принят",
    VOTING_THANK_YOU: "Спасибо",
    ML_MODEL_ALLOWED_PARAMETERS: "Совместима с параметрами",
    ML_MODEL_TYPE: "Тип модели",
    MODELS: "Модели",
    OTHER_MODELS: "Другие модели",
  },

  pricing: {
    buy: "Купить",
    unlimited: "Безлимитно",
    none: "Нет",
    yes: "Есть",
    up: "До",
    individual: "Индивидуально",
    days: "дней",
    month: "/мес",
    year: "/год",
    reg: "Зарегистрироваться",
    call: "Связаться",
    desc: "В таблице Вы можете увидеть возможности, наборы функций и цены платформы для разных типов пользователей",
    choose: "Выберите тариф",
    head: {
      leader: "РУКОВОДИТЕЛЬ",
      analyst: "АНАЛИТИК",
      consultant: "КОНСУЛЬТАНТ",
      special: "СПЕЦПРОЕКТ",
    },
    row1: {
      head: "Количество членов команды",
      analyst: {
        desc: "Дополнительный пользователь $12",
      },
      consultant: {
        desc: "Дополнительный пользователь $12",
      },
    },
    row2: {
      head: "Количество устройств",
      analyst: {
        desc: "Дополнительное устройство $12",
      },
      consultant: {
        desc: "Дополнительное устройство $12",
      },
    },
    row3: {
      head: "Бесплатный пробный период",
    },
    row4: {
      head: "Техническая поддержка",
      analyst: {
        text: "24/7",
        desc: "E-mail, Skype",
      },
      consultant: {
        text: "24/7",
        desc: "E-mail, Skype, WhatsApp, Telegram, TeamViewer",
      },
      special: "Персональная 24/7",
    },
    row5: {
      head: "Кастомизация сервиса с использованием логотипа пользователя",
    },
    row6: {
      head: "Постоянные источники данных",
      leader: "Только свои устройства",
      analyst: "Только свои устройства",
      consultant: "Устройства ваших конечных клиентов + API",
      special: "Индивидуально + API + ИИ",
    },
    row7: {
      head: "Создание отчетов",
      leader: "Только чтение",
    },
    row8: {
      head: "Логирование заходов пользователей",
    },
    row9: {
      head: "Ограничение заходов по IP",
    },
    row10: {
      head: "Соглашение об уровне обслуживания",
    },
    row11: {
      head: "Основные возможности",
      el1: "Работа с данными, готовыми к оценке на дашбордах, графиках и отчётах",
      el2: "Наблюдение и принятие решений",
      el3: "Передача наборов данных по ссылке",
      el4: "Поручения АНАЛИТИКАМ и КОНСУЛЬТАНТАМ",
      el5: "Подключение устройств к платформе",
      el6: "Формирование команды АНАЛИТИКОВ и РУКОВОДИТЕЛЕЙ",
      el7: "Загрузка на платформу и обработка собственных наборов данных",
      el8: "Работа с собственными сырыми, чистыми, не имеющими пробелов данными (small data)",
      el9: "Установка собственных предельных параметров",
      el10: "Установка собственных алертов",
      el11: "Подготовка и отправка отчётов",
      el12: "Подготовка данных для РУКОВОДИТЕЛЕЙ",
      el13: "Кастомизация платформы с использованием вашего логотипа",
      el14: "Получение доступных наборов данных по API",
      el15: "Почти всё, что нужно для ведения бизнеса, исследований или преподавания",

      special: {
        el1: "Интеграция с CRM",
        el2: "Наборы данных, разделенные по: стране, текущим, прогнозным, историческим, историческим прогнозным",
        el3: "Спутниковые изображения",
        el4: "Карты",
        el5: "Модели",
        el6: "ИИ",
      },
    },
    row12: {
      head: "Доступ по API",
      consultant: {
        el1: "Массивы данных",
      },
      special: {
        el1: "Пользователи с особыми требованиями",
        el2: "Организации и учреждения, которым необходимы локальные или закрытые системы и оперирование в крупных диспетчерских центрах",
        el3: "Государственные структуры",
        el4: "Крупные промышленные предприятия",
        el5: "Консалтинговые корпорации",
      },
    },
    row13: {
      head: "Кому подходит",
      leader: {
        el1: "Лица принимающие решения",
        el2: "Руководители",
        el3: "Менеджеры",
        el4: "Инспекторы",
        el5: "Супервайзеры",
      },
      analyst: {
        el1: "Самостоятельные компании, использующие платформу только для своих нужд",
        el2: "Промышленные предприятия малого и среднего бизнеса",
        el3: "Жилищно-коммунальные службы, управляющие компании и товарищества собственников жилья",
        el4: "Архитекторы",
        el5: "Строители",
        el6: "Агрономы",
        el7: "Экологи",
        el8: "Инженеры по технике безопасности, санитарным нормам и охране окружающей среды",
        el9: "Штатные аналитики и статистики",
        el10: "Специалисты по удалённому зондированию и сенсоризации",
        el11: "Профильные оперативные специалисты из отрасли",
        el12: "Предприятия горнодобывающей промышленности",
        el13: "Предприятия дорожного сектора",
        el14: "Школьные кружки по экологии, охране окружающей среды, изменению климата, устойчивому развитию и интернету вещей",
      },
      consultant: {
        el1: "Консультанты и компании-консультанты в области экологии, охраны окружающей среды и риск-менеджмента, а также лаборатории, у которых есть собственные конечные клиенты под план РУКОВОДИТЕЛЬ",
        el2: "Компании оказывающие услуги экологической телематики / телематики охраны окружающей среды",
        el3: "Компании специализирующиеся на мониторинге окружающей среды (включая воздух, воду, почву, растительность, климат и погоду среди прочего) по спутниковым снимкам, для уточнения своих данных с использованием сети наземных датчиков",
        el4: "Учёные, преподаватели и учителя, работающие с большим количеством студентов, выполняющих практику/лабораторные работы по плану АНАЛИТИК",
        el5: "Дата-сайентисты и специалисты, работающие с большими данными",
      },
      special: {
        el1: "Пользователи с особыми требованиями",
        el2: "Организации и учреждения, которым необходимы локальные или закрытые системы и оперирование в крупных диспетчерских центрах",
        el3: "Государственные структуры",
        el4: "Крупные промышленные предприятия",
        el5: "Консалтинговые корпорации",
      },
    },
  },
};
